.container {
    display: flex;
    align-items: center;
    border-left: 5px solid var(--error-red);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.20);
    margin-bottom: 20px;
    padding: 15px;
    transform: scaleY(1);
    transition: all 0.5s ease;
    background:white;
}

.container > svg {
    margin-right: 15px;
    width: 20px;
}

.innerContainer{
    max-width: calc(100% - 35px);
}

.container p{
    margin-top: 5px;
    color: var(--text-gray);
    font-size: 18px;
}

.hidden{
    margin:0;
    transform: scaleY(0);
}
