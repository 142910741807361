.sentSMS-icon {
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: #00ba98;
    z-index: 9999;
}

.repair-image-container {
    background: #b1b1b1;
}

.repair-edit-images .service-item-image {
    width: calc(50% - 10px);
    margin-top: 10px;
}

.repair-edit-images .service-item-image:nth-child(2n) {
    margin-left: 10px;
}

.repair-edit-images .service-item-image:nth-child(2n+1) {
    margin-right: 10px;
}

.repair-edit-images .service-item-image:first-child, .repair-edit-images .service-item-image:nth-child(2) {
    margin-top: 0;
}

.floating-bar {
    position: fixed;
    left: 15%;
    bottom: 0;
    right: 0;
}

.status-bar > div svg {
    width: 6vh;
    height: auto;
}

.status-bar > div, .status-bar > div svg {
    z-index: 5;

}

.status-bar > div:before {
    content: "";
    height: 10px;
    position: absolute;
    top: calc(50% - 5px);
    left: 0;
    right: 0;
    background: red;
    bottom: 0;
    z-index: 0;
    width: calc(50% - 1.25rem - 9vh + 1px);
}


.status-bar > div.sent-sms:before {
    right: unset;
    left: calc(1.25rem + 6vh);
}

.status-bar > div.serviced:before {
    display: none;
}

.status-bar > div.active:before {
    background: #00BA98
}

.status-bar > div.inactive:before {
    background: #EAEAEA
}

.status-bar > div.delivered:before {
    right: calc(1.25rem + 6vh);
    left: unset;
}

.delete-button {
    background: rgba(243, 81, 81, 0.3);
    min-width:54px;
}

.action-button {
    background: #EAEAEA;
    min-width:54px;
}

.deliver-button {
    background: var(--green);
    min-width:54px;
}

.serviced-button button {
    background: #7C7C7C !important;
    color: white;
    border: unset;
    font-size: 1.125rem !important;
    display: flex;
    justify-content: center;
    min-height: 54px;
}

.serviced-button button span {
    display: block;
    width: max-content;
    flex: unset;
}

.serviced-button button:first-child {
    border-right: 1px solid white !important;
    gap: 1.25rem
}

.action-buttons > * {
    /*height: 6vh;*/
    /*width: 6vh;*/
    display: flex;
    align-items: center;
}

.action-buttons svg {
    display: block;
}

.serviced-button {
    background: #7C7C7C !important;
    color: white;
    width: calc(100% - 24vh)
}

.main-tab {
    height: calc(100vh - 19.5rem);
    overflow: auto;
}

.repair-tab svg {
    width: 25px;
    height: auto;
}

.repair-tab {
    color: #7C7C7C;
}


.repair-tab.selected svg,
.repair-tab.selected svg * {
    fill: white !important;
}

.repair-tab.selected {
    background: var(--green);
    color: white;
}

.thumbnail-image .eye {
    background: rgba(0, 0, 0, 0.30);
    opacity: 0;
    transition: opacity 0.5s ease;
    cursor: pointer;
}

.thumbnail-image:hover .eye {
    opacity: 1;
}

.primereact-image-hidden {
    position: absolute;
    left: -100%;
    width: 100%;
}

.current-location {
    background: #EAEAEA;
}

.transfer-to-store-button {
    background: var(--green);
}

.transfer-to-partner-button {
    background: #7C7C7C;
}

.location-history {
    min-height: calc(100vh - 30rem);
    z-index: 2;
}

.horizontal-line {
    position: absolute;
    left: 20px;
    top: 10px;
    bottom: 10px;
    width: 2px;
    background: #EAEAEA;
    z-index: -1;
}

.location-history-item {
    padding: 1rem 0;
}

.location-history > div:nth-child(2), .location-history > div:first-child {
    padding-top: 0 !important;
}

.location-history > div:last-child {
    padding-bottom: 0 !important;
}

.left-col {
    width: 20%;
    max-height: calc(100vh - 14rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.right-col {
    width: 80%;
}

.sent-sms.from-red-to-green::before {
    background: linear-gradient(90deg, #ff0000, #00ba98);
}
