@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

*:not(table) {
  box-sizing: initial;
}

body {
  margin: 0;
  padding: 0;
  background-color: #eeeeee;
  font-family: var(--font-regular);
}

h2 {
  margin: 0;
}
p {
  margin: 0;
}

.green {
  color: var(--green);
}

.css-189ousr-MuiPopper-root-MuiPickersPopper-root {
  z-index: 4400 !important;
}

.css-f53ilk-MuiClockPointer-thumb,
.css-1fiopb0-MuiClockPointer-thumb {
  border: 16px solid #00ba98 !important;
}

.css-1h2qg9i-MuiClockPointer-root,
.css-umzx0k-MuiClock-pin,
.css-pncb2q-MuiClockPointer-root,
.css-innj4t-MuiPickersYear-yearButton.Mui-selected,
.css-qct7wd-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-4k4mmf-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-1tci2yf-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.css-1dq2mpt-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background: #00ba98 !important;
}
.css-1uent87-MuiButtonBase-root-MuiButton-root {
  color: #00ba98 !important;
}
