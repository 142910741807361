.progressBarContainer{
    position: absolute;
    bottom: 50px;
    left:50px;
    right:50px;
    z-index:1;
}

.progressBar{
    border: 2px solid white;
}

@media(max-width:650px){
    .progressBarContainer {
        left:30px;
        right:30px;
        bottom:30px;
    }
}
