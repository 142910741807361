/*URW*/
@font-face {
    font-family: "urw_geometric_extlight";
    src: url("./../../public/fonts/urwgeometricext-light-webfont.eot");
    src: url("./../../public/fonts/urwgeometricext-light-webfont.eot?#iefix") format("embedded-opentype"), url("./../../public/fonts/urwgeometricext-light-webfont.woff2") format("woff2"), url("./../../public/fonts/urwgeometricext-light-webfont.woff") format("woff"), url("./../../public/fonts/urwgeometricext-light-webfont.ttf") format("truetype"), url("./../../public/fonts/urwgeometricext-light-webfont.svg#urw_geometric_extlight") format("svg");
    font-weight: normal;
    font-style: normal;
    ascent-override: 80%;
    font-display: swap;
}

@font-face {
    font-family: "urw_geometric_extregular";
    src: url("./../../public/fonts/urwgeometricext-regular-webfont.eot");
    src: url("./../../public/fonts/urwgeometricext-regular-webfont.eot?#iefix") format("embedded-opentype"), url("./../../public/fonts/urwgeometricext-regular-webfont.woff2") format("woff2"), url("./../../public/fonts/urwgeometricext-regular-webfont.woff") format("woff"), url("./../../public/fonts/urwgeometricext-regular-webfont.ttf") format("truetype"), url("./../../public/fonts/urwgeometricext-regular-webfont.svg#urw_geometric_extregular") format("svg");
    font-weight: normal;
    font-style: normal;
    ascent-override: 80%;
    font-display: swap;
}

@font-face {
    font-family: "urw_geometric_extsemi_bold";
    src: url("./../../public/fonts/urwgeometricext-semibold-webfont.eot");
    src: url("./../../public/fonts/urwgeometricext-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("./../../public/fonts/urwgeometricext-semibold-webfont.woff2") format("woff2"), url("./../../public/fonts/urwgeometricext-semibold-webfont.woff") format("woff"), url("./../../public/fonts/urwgeometricext-semibold-webfont.ttf") format("truetype"), url("./../../public/fonts/urwgeometricext-semibold-webfont.svg#urw_geometric_extsemi_bold") format("svg");
    font-weight: normal;
    font-style: normal;
    ascent-override: 80%;
    font-display: swap;
}

@font-face {
    font-family: "urw_geometric_extbold";
    src: url("./../../public/fonts/urwgeometricext-bold-webfont.eot");
    src: url("./../../public/fonts/urwgeometricext-bold-webfont.eot?#iefix") format("embedded-opentype"), url("./../../public/fonts/urwgeometricext-bold-webfont.woff2") format("woff2"), url("./../../public/fonts/urwgeometricext-bold-webfont.woff") format("woff"), url("./../../public/fonts/urwgeometricext-bold-webfont.ttf") format("truetype"), url("./../../public/fonts/urwgeometricext-bold-webfont.svg#urw_geometric_extbold") format("svg");
    font-weight: normal;
    ascent-override: 80%;
    font-style: normal;
}

/*SF PRO*/
@font-face {
    font-family: "sf_pro_thin";
    src: url("./../../public/fonts/sf-pro/SFPRODISPLAYREGULAR.OTF");
    font-weight: normal;
    font-style: normal;
    ascent-override: 80%;
    font-display: swap;
}

@font-face {
    font-family: "sf_pro_regular";
    src: url("./../../public/fonts/sf-pro/SFPRODISPLAYMEDIUM.OTF");
    font-weight: normal;
    font-style: normal;
    ascent-override: 80%;
    font-display: swap;
}

@font-face {
    font-family: "sf_pro_bold";
    src: url("./../../public/fonts/sf-pro/SFPRODISPLAYBOLD.OTF");
    font-weight: normal;
    font-style: normal;
    ascent-override: 80%;
    font-display: swap;
}
