.order-confirmation {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.order-confirmation .card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.order-confirmation .header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--surface-border);
}

.order-confirmation .company-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 2rem;
}

.order-confirmation .logo {
    max-width: 200px;
    height: auto;
    object-fit: contain;
}

.order-confirmation .company-info {
    flex: 1;
}

.order-confirmation .company-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.order-confirmation .company-info p {
    margin: 0.25rem 0;
    font-size: 1rem;
    color: var(--text-color-secondary);
}

.order-confirmation .legal-entity,
.order-confirmation .company-info {
    flex: 1;
}

.order-confirmation .company-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.order-confirmation .legal-entity p,
.order-confirmation .company-info p {
    margin: 0.25rem 0;
    font-size: 0.9rem;
}

.order-confirmation h1 {
    margin: 0;
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.order-confirmation .order-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.order-confirmation .order-details h2 {
    color: var(--text-color);
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
}

.order-confirmation .description {
    color: var(--text-color-secondary);
    margin: 0;
    font-size: 1rem;
}

.order-confirmation .offer-info {
    text-align: right;
}

.order-confirmation .expiration-date {
    font-weight: 600;
    margin: 0 0 0.25rem 0;
}

.order-confirmation .products-section {
    margin-bottom: 2rem;
}

.order-confirmation .total-section {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    border-top: 2px solid var(--surface-border);
    margin-bottom: 2rem;
}

.order-confirmation .total-amount {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text-color);
    margin: 0;
}

.order-confirmation .actions-section {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
}

.order-confirmation .actions-section .p-button {
    min-width: 120px;
}

/* Responsive Design */
@media screen and (max-width: 960px) {
    .order-confirmation {
        padding: 1rem;
    }

    .order-confirmation .card {
        padding: 1rem;
    }

    .order-confirmation .company-header {
        flex-direction: column;
        align-items: center;
    }

    .order-confirmation .logo {
        width: 200px;
        padding-right: 0;
        margin-bottom: 1rem;
    }

    .order-confirmation .legal-entity,
    .order-confirmation .company-info {
        text-align: center;
        width: 100%;
    }

    .order-confirmation .order-info {
        flex-direction: column;
    }

    .order-confirmation .offer-info {
        text-align: left;
        margin-top: 1rem;
    }

    .order-confirmation .actions-section {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }

    .order-confirmation .actions-section .p-button {
        width: 100%;
    }
} 

.repair-price-confirmation-public {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.repair-price-confirmation-public .card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.repair-price-confirmation-public .header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--surface-border);
}

.repair-price-confirmation-public .company-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 2rem;
}

.repair-price-confirmation-public .logo {
    max-width: 200px;
    height: auto;
    object-fit: contain;
}

.repair-price-confirmation-public .company-info {
    flex: 1;
}

.repair-price-confirmation-public .company-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.repair-price-confirmation-public .company-info p {
    margin: 0.25rem 0;
    font-size: 1rem;
    color: var(--text-color-secondary);
}

.repair-price-confirmation-public .legal-entity,
.repair-price-confirmation-public .company-info {
    flex: 1;
}

.repair-price-confirmation-public .company-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.repair-price-confirmation-public .legal-entity p,
.repair-price-confirmation-public .company-info p {
    margin: 0.25rem 0;
    font-size: 0.9rem;
}

.repair-price-confirmation-public h1 {
    margin: 0;
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.repair-price-confirmation-public .repair-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.repair-price-confirmation-public .repair-details h2 {
    color: var(--text-color);
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
}

.repair-price-confirmation-public .description {
    color: var(--text-color-secondary);
    margin: 0;
    font-size: 1rem;
}

.repair-price-confirmation-public .customer-info {
    text-align: right;
}

.repair-price-confirmation-public .customer-name {
    font-weight: 600;
    margin: 0 0 0.25rem 0;
}

.repair-price-confirmation-public .customer-phone {
    color: var(--text-color-secondary);
    margin: 0;
}

.repair-price-confirmation-public .products-section {
    margin-bottom: 2rem;
}

.repair-price-confirmation-public .total-section {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    border-top: 2px solid var(--surface-border);
    margin-bottom: 2rem;
}

.repair-price-confirmation-public .total-amount {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text-color);
    margin: 0;
}

.repair-price-confirmation-public .actions-section {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
}

.repair-price-confirmation-public .actions-section .p-button {
    min-width: 120px;
}

/* Responsive Design */
@media screen and (max-width: 960px) {
    .repair-price-confirmation-public {
        padding: 1rem;
    }

    .repair-price-confirmation-public .card {
        padding: 1rem;
    }

    .repair-price-confirmation-public .company-header {
        flex-direction: column;
        align-items: center;
    }

    .repair-price-confirmation-public .logo {
        width: 200px;
        padding-right: 0;
        margin-bottom: 1rem;
    }

    .repair-price-confirmation-public .legal-entity,
    .repair-price-confirmation-public .company-info {
        text-align: center;
        width: 100%;
    }

    .repair-price-confirmation-public .repair-info {
        flex-direction: column;
    }

    .repair-price-confirmation-public .customer-info {
        text-align: left;
        margin-top: 1rem;
    }

    .repair-price-confirmation-public .actions-section {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }

    .repair-price-confirmation-public .actions-section .p-button {
        width: 100%;
    }
}

.thank-you-card {
    max-width: 600px;
    margin: 2rem auto;
    padding: 3rem 2rem;
}

.thank-you-card .text-6xl {
    font-size: 4rem;
} 