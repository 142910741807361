.p-component, .p-component * {
    box-sizing: border-box;
}

.p-panel-title {
    width: 100%;
}

.p-paginator-bottom .p-dropdown {
    border-radius: 0;
    border: 1px solid #dee2e6;
}

.p-datatable .p-datatable-tbody > tr.p-highlight{
    color:black;
    background: #00ba9878;
}

circle.p-progress-spinner-circle {
    stroke: #00BA98FF !important;
}

.p-tieredmenu .p-menuitem-link {
    gap: 1rem;
}

.p-tieredmenu {
    width: 20rem;
}

.p-selectbutton .p-button.p-component.p-highlight {
    background: var(--green);
}

.p-selectbutton * {
    border: unset
}

.p-tabview-panels {
    padding: 1.25rem 0 0 0;
}

.p-toast{
    z-index:9999999 !important;
}

.p-toast .p-toast-message.p-toast-message-success{
    background: #cff2ec;
    border-radius: 10px;
}

input.p-password-input {
    width: 100%;
}

.p-button.p-button-success:not(:disabled):hover, .p-buttonset.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-success:not(:disabled):hover{
    background: var(--green) !important;
    border-color: var(--green) !important;
}
.p-tag.p-tag-secondary {
    background: #6c757d;
}