.container {
    display: flex;
    align-items: center;
}

.right,
.left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left {
    background: var(--green);
    padding: 50px;
    height: calc(100vh - 100px);
}

.right {
    width: 70%;
    height: 100vh;
}

.logoSvg{
    width: 40%;
    height: auto;
    position: absolute;
    top: 50px;
    left: 50px;
}

.vectorContainer{
    position: relative;
    width:100%;
    display:flex;
    align-items:center;
    justify-content: center;
}

body{
    background: white;
}


.loginVector{
    padding: 0 10%;
    width: 80%;
    height: auto;
    z-index: 1;
}

@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.LogoSvg{
    position: absolute;
    width: 21%;
    height: auto;
    top: 15%;
    right: 63%;
    z-index: -1;
    animation: rotateAnimation 10s linear infinite;
}

.loginVectorGear2{
    position: absolute;
    width: 11%;
    height: auto;
    top: 20%;
    right: 38%;
    z-index: -1;
    animation: reverse rotateAnimation 7.5s linear infinite;
}

.loginVectorGear3{
    position: absolute;
    width: 27%;
    height: auto;
    top: 3.95%;
    right: 10.82%;
    z-index: -1;
    animation: rotateAnimation 15s linear infinite;
}

@media(max-width:650px){
    .left{
        padding: 30px;
        height: calc(100vh - 60px);
    }
    .logoSvg{
        top: 30px;
        left: 30px;
    }
}
