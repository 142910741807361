.button{
    background: var(--green);
    width: calc(100% - 40px);
    font-size: 25px;
    line-height: 22px;
    font-family: var(--font-bold);
    color: white;
    padding: 20px;
    border-radius: var(--border-radius);
    display:flex;
    align-items:center;
    justify-content:center;
    gap:20px;
}

.white{
    background: white;
    color: var(--green);
}

