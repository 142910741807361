/* Navbar Component */
.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: calc(15% - 60px);
  height: calc(100vh - 60px);
  top: 0;
  background: #ffffff;
  justify-content: space-between;
}

.logo svg{
  width: 90%;
  margin-bottom: 30px;
  display:block;
  height: auto;
}

.linkList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* NavbarBottom Component */
.navbar-bottom {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
}

.navbar-icon {
  font-size: 20px;
  color: #555;
  margin-right: 10px;
}

.navbar-text {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}
a:link {
  text-decoration: none;
}

.label {
  font-family: var(--font-regular);
  font-size: 17px;
  line-height: 17px;
  color: #7C7C7C;
}
