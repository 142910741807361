@media print {
  .repair-delivery-receipt .page:first-child {
    page-break-after: always !important;
  }
  .repair-delivery-receipt .absolute {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  /* All pages will be white and with a padding */
  .repair-delivery-receipt .page {
    background-color: white !important;
    padding: 20px !important;
    margin: 0px !important;
  }
}
