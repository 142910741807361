.inputContainer {
    position: relative;
    cursor: text;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: text;
    padding: 5px;
}

.input {
    border: unset;
    outline: unset;
    background: transparent;
    font-family: var(--font-bold);
    font-size: 1.4rem;
    cursor: text;
    color:white;
    border-radius: var(--border-radius);
    transition: all 0.5s ease;
    width:100%
}

.label {
    position: absolute;
    top: 5px;
    left: 5px;
    font-family: var(--font-light);
    font-size: 1.2rem;
    cursor: text;
    transition: all 0.5s ease;
    color: white;
}

.input:not(:placeholder-shown) + label,
.input:focus + label{
    font-size: .9rem;
    color: rgba(255, 255, 255, 0.75);
    left: 0;
    top: 5px;
}

.input:not(:placeholder-shown),
.input:focus{
    padding: 1.5rem 0 0 0;
}

.passwordVisibilityButton{
    position: absolute;
    top:25px;
    right: 20px;
    background: transparent;
}


/*placeholder hidden*/
.input::-webkit-input-placeholder {
    color: transparent;
}
.input:-moz-placeholder {
    color: transparent;
}
.input::-moz-placeholder {
    color: transparent;
}
.input:-ms-input-placeholder {
    color: transparent;
}

.iconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.25);
    margin: 0.7rem;
}

.icon{
    width: 100%;
    height: auto;
}

.inputInnerContainer{
    position: relative;
    width: calc(100% - 5rem);
}

@media(max-width:650px){
    .label{
        font-size: 1.1rem;
        top: 6px;
    }
}
