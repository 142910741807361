.fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
}

body .fc .fc-day-today,
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background: #00ba9810;
}

a.fc-col-header-cell-cushion {
  font-size: 15px;
  font-weight: 100;
  color: #ababab;
}

.fc-button:hover {
  color: white !important;
}

.fc-button:hover:disabled {
  color: #7c7c7c !important;
}

.fc-timegrid-event .fc-event-main {
  padding: 0.5rem;
}

button.fc-button.fc-button-primary.fc-button-active,
button.fc-button.fc-button-primary.fc-button-active:active {
  color: white !important;
  box-shadow: none !important;
}

.fc-timegrid-event-harness {
  margin: 0.3rem 0rem 0.3rem 0.2rem;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-size: 13px;
  color: #ababab;
  font-weight: 200 !important;
}

.fc .fc-timegrid-event .fc-event-time {
  line-height: 11px;
  margin: 0;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:active {
  box-shadow: none;
}

.fc .fc-col-header-cell {
  padding: 0.7rem;
}

.fc .fc-timegrid-slot {
  height: 2em;
}
.fc .fc-button-primary.fc-newRepairButton-button {
  background: var(--green);
  color: white;
  text-wrap: nowrap;
  border: unset;
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
  box-shadow: 0 5px 5px #e3e3e3;
}

.fc-v-event .fc-event-title {
  line-height: 12px;
}

.fc
  th.fc-col-header-cell.fc-day.fc-day-fri.fc-day-today
  a.fc-col-header-cell-cushion {
  color: white;
}

.fc th.fc-col-header-cell.fc-day.fc-day-fri.fc-day-today {
  background: var(--green);
  border-color: var(--green);
}

.fc-day-other {
  background: #efefef61;
}

.fc .fc-daygrid-day-top {
  flex-direction: column;
  font-size: 13px;
  color: #8b8b8b;
  padding: 0.4rem;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr div.fc-toolbar-chunk:first-child {
  width: 100%;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  border-radius: 5px 5px 0 0;
  padding: 1rem 1.5rem;
  background: #efefef;
}

.fc .fc-toolbar-title {
  font-size: 1.1rem;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr div.fc-toolbar-chunk:nth-child(2) {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid #d7d7d7;
  display: flex;
  gap: 10px;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr div.fc-toolbar-chunk:nth-child(3) {
  display: flex;
  gap: 5px;
  white-space: nowrap;
}

button.fc-spacingButton-button.fc-button.fc-button-primary {
  width: 180px;
  visibility: hidden;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin: 5px 2px;
}

span.fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner {
  font-size: 9px;
  color: #ababab;
  font-weight: 200 !important;
  display: block;
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  max-width: 100%;
  line-height: 1.2;
  text-align: center;
}

:root {
  --fc-button-bg-color: white;
  --fc-button-text-color: #7c7c7c;
  --fc-button-border-color: #dadada;
  --fc-button-hover-bg-color: var(--green);
  --fc-button-active-bg-color: var(--green);
  --fc-button-active-border-color: var(--green);
  --fc-button-hover-text-color: white;
  --fc-button-hover-border-color: var(--green);
  --fc-border-color: #f0f0f0;
  --fc-today-bg-color: rgba(211, 10, 10, 0);
}
