@keyframes ldio-tzqoa72rxz7-o {
    0% {
        opacity: 1;
        transform: translate(0 0)
    }

    49.99% {
        opacity: 1;
        transform: translate(40px, 0)
    }

    50% {
        opacity: 0;
        transform: translate(40px, 0)
    }

    100% {
        opacity: 0;
        transform: translate(0, 0)
    }
}

@keyframes ldio-tzqoa72rxz7 {
    0% {
        transform: translate(0, 0)
    }

    50% {
        transform: translate(40px, 0)
    }

    100% {
        transform: translate(0, 0)
    }
}

.ldio-tzqoa72rxz7 div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.ldio-tzqoa72rxz7 div:nth-child(1) {
    background: var(--green);
    animation: ldio-tzqoa72rxz7 1.0204081632653061s linear infinite;
    animation-delay: -0.5102040816326531s;
}

.ldio-tzqoa72rxz7 div:nth-child(2) {
    background: var(--background-gray);
    animation: ldio-tzqoa72rxz7 1.0204081632653061s linear infinite;
    animation-delay: 0s;
}

.ldio-tzqoa72rxz7 div:nth-child(3) {
    background: var(--green);
    animation: ldio-tzqoa72rxz7-o 1.0204081632653061s linear infinite;
    animation-delay: -0.5102040816326531s;
}

.loadingio-spinner-dual-ball-l96bgipof6 {
    width: 80px;
    height: 40px;
    display: inline-block;
    overflow: hidden;
}

.ldio-tzqoa72rxz7 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-tzqoa72rxz7 div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */
.loading-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000024;
    z-index: 10;
}

.loading-animation.inactive {
    display: none;
}
