.repair {
    width: calc(16.66% - 1.04rem);
}
.repair-grid-container {
    height: calc(100vh - 7.75rem);
}
.skeleton-repair{
    background:#d7d7d7;
    min-height:400px;
}
