.link {
    display: flex;
    align-items: center;
    padding: 7px 0;
    cursor: pointer;
    margin-bottom: 10px;
    width:100%;
    justify-content:space-between;
}

.active .label {
    color:black;
    font-family: var(--font-semi-bold);
}

.iconContainer {
    width: 30px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconContainer svg {
    height: 22px;
    width: auto;
}

.label {
    font-family: var(--font-regular);
    font-size: 16px;
    line-height: 16px;
    color: #7C7C7C;
}
